<template>
  <div id="app">
    <header>
      <div class="hero">
        <div class="brand">
          <img
            src="@/assets/images/nampil_logo.png"
            alt="Nampil Studio Logo"
            class="logo"
          />
          <h1 class="brand-name">Nampil Studio</h1>
        </div>
      </div>
    </header>
    <main class="main">
      <article>
        <h2>Web <span>Development</span></h2>
      </article>
      <article>
        <h2>Brand <span>Identity</span></h2>
      </article>
      <article>
        <h2><span class="top">Creative</span> Design</h2>
      </article>
      <article>
        <h2><span class="top">Digital</span> Marketing</h2>
      </article>
      <article>
        <h2>Media <span>Video&Photo</span></h2>
      </article>
    </main>
    <footer>
      <div class="contact">
        <p>contact@nampilstudio.com</p>
      </div>
    </footer>
    <div class="sides">
      <p class="coming-soon">Coming Soon</p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'App'
}
</script>

<style lang="scss">
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
body {
  font-family: 'Source Sans Pro', sans-serif;
  color: #fff;
}
#app {
  height: 100vh;
  overflow: hidden;
  position: relative;
  background-image: url('./assets/images/bg.jpg');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  text-align: center;
}
header {
  display: flex;
  align-items: center;
  margin-bottom: 40px;
  flex-grow: 1;
}
.logo {
  width: 128px;
  height: auto;
}

.brand-name {
  font-family: 'Encode Sans', sans-serif;
  font-weight: 600;
  font-size: 2.25rem;
}

.sides {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  writing-mode: vertical-lr;
  rotate: 180deg;
  padding: 0.25rem;
}

.coming-soon {
  --orn-size: 70%;
  --orn-padding: 1em;
  text-align: center;
  font-weight: 400;
  position: relative;
  &::after,
  &::before {
    content: '';
    height: var(--orn-size);
    width: 1px;
    position: absolute;

    left: 50%;
    opacity: 0.5;
    background-color: #fff;
  }
  &::after {
    transform: translate(-50%, calc(var(--orn-size) + var(--orn-padding)));
    top: 50%;
  }
  &::before {
    transform: translate(
      -50%,
      calc((-1 * var(--orn-size)) - var(--orn-padding))
    );
    bottom: 50%;
  }
}

main {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: center;
}
article {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 33%;
  margin-bottom: 40px;
  &:first-child {
    align-items: flex-start;
  }
  &:nth-child(3) {
    align-items: flex-end;
  }
  h2 {
    font-size: 1.7rem;
    padding: 0;
    margin: 0;
    color: #dfdfdf;
    position: relative;
    text-align: center;
  }

  span {
    position: absolute;
    bottom: 0;
    left: 0;
    transform: translateY(55%);
    font-size: 0.5em;
    font-weight: 300;
  }
  span.top {
    bottom: unset;
    top: 0;
    transform: translateY(-55%);
  }
}

p {
  font-weight: 300;
  margin: 0;
  color: #c9c9c9;
  font-size: 0.9rem;
}

.contact p {
  font-weight: bold;
}

@media (min-width: 580px) {
}
@media (min-width: 768px) {
  .brand {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .logo {
    margin-right: 25px;
  }
  .brand-name {
    font-size: 3rem;
  }
  main {
    h2 {
      font-size: 2rem;
    }
  }
  article {
    width: auto;
    margin: 0 30px 50px;
  }
}
@media (min-width: 992px) {
  p {
    font-size: 1rem;
  }
}
@media (min-width: 1200px) {
  .logo {
    width: 200px;
    margin-right: 40px;
  }
  .brand-name {
    font-size: 4rem;
  }
  main {
    h2 {
      font-size: 2.5rem;
    }
  }
}
</style>
